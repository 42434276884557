import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Information as InformationView,
  SettingIMS,
  QuickMenu,
  SettingCMMS,
  Notify,
} from '../views/Information';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import { Loading } from '../components/Loading';
import { NotFound } from '../components/Error';
import accessRight from '../utils/functions/accessRight';

export function Information() {
  const module = 'INFORMATION';
  const prefix = '/information';
  const name = 'ข้อมูลระบบ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const me = useSelector((state) => state.me);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : Information');
    dispatch(actions.meGet());
    return () => {};
  }, []);

  if (!me.userData) {
    return <Loading />;
  }
  if (accessRight(me, module)) {
    return (
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              {/* <Redirect exact from={`${prefix}`} to={`${prefix}`} /> */}
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}`}>
                <InformationView title={'ข้อมูลระบบ'} subtitle={name} />
              </Route>
              {accessRight(me, module, 1) && (
                <Switch>
                  <Route exact path={`${prefix}/setting-ims`}>
                    <SettingIMS
                      title={'ตั้งค่าระบบคลังวัสดุ'}
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/setting-cmms`}>
                    <SettingCMMS
                      title={'ตั้งค่าระบบการแจ้งซ่อม'}
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/quick-menu`}>
                    <QuickMenu
                      title={'ตั้งค่าเมนูใช้งานด่วน'}
                      subtitle={name}
                    />
                  </Route>
                  <Route exact path={`${prefix}/notify`}>
                    <Notify title={'การแจ้งเตือน'} subtitle={name} />
                  </Route>{' '}
                  <Route exact path={`${prefix}/notify/`}>
                    <Notify title={'การแจ้งเตือน'} subtitle={name} />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                  <Route path="/*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return <></>;
}

export default Information;
